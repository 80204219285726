import { render, staticRenderFns } from "./StoriesAdd.vue?vue&type=template&id=20bfc29d&scoped=true&"
import script from "./StoriesAdd.vue?vue&type=script&lang=js&"
export * from "./StoriesAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20bfc29d",
  null
  
)

export default component.exports