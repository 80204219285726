<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <b-card class="">
          <h4>ساخت استوری</h4>

          <b-row>
            <b-col
                cols="12"
                md="6"
                class="my-2"
            >
              <b-form-group
                  label="نوع استوری"
                  label-for="type"
              >
                <v-select
                    v-model="storyData.priority"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                />
              </b-form-group>
            </b-col>

            <b-media v-if="storyData.priority === 2" class="my-2 col-12">
              <template>
                <b-avatar
                    class="cursor-pointer"
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                    @click.native="$refs.refInputEl1.click()"
                >
                  <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
                </b-avatar>
              </template>
              <div class="d-none flex-wrap mt-1">
                <b-button
                    variant="primary"
                >
                  <input
                      ref="refInputEl1"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic($event,'base64ImageSrc')"
                  >
                  <span class="d-none d-sm-inline">+</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deletePic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <b-media v-if="storyData.priority === 3" class="my-2 col-12">
              <template>
                <b-avatar
                    v-if="base64VideoSrc === null"
                    class="cursor-pointer"
                    ref="previewEl2"
                    size="300px"
                    rounded
                    @click.native="$refs.refInputEl2.click()"
                >
                  <feather-icon icon="PlusSquareIcon" size="100"/>
                </b-avatar>
                <video
                    v-else
                    :src="base64VideoSrc"
                    autoplay
                    style="max-height: 30rem;max-width: 100%"
                ></video>
              </template>
              <div class="d-none flex-wrap mt-1">
                <b-button
                    variant="primary"
                >
                  <input
                      ref="refInputEl2"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic($event,'base64VideoSrc')"
                  >
                  <span class="d-none d-sm-inline">+</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deletePic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <b-alert
                :show="storyData.priority === 3"
                variant="danger"
                class=" col-12"
            >
              <div class="alert-body ">
                <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                />
                <span class="ml-25">حجم فیلم مورد نظر باید کمتر از 25 مگابایت باشد.</span>
              </div>
            </b-alert>

          </b-row>

          <b-button
              variant="primary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="setStories"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import vSelect from "vue-select";
import {StorySetStoryRequest} from "@/libs/Api/Story";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  title: "ساخت استوری - پنل ادمین مکس ",
  name: "StoriesAdd",
  data() {
    return {
      overlay: false,
      typeOptions: [
        {label: 'عکس', value: 2},
        {label: 'فیلم', value: 3}
      ],
      base64ImageSrc: null,
      base64VideoSrc: null,
      storyData: {
        base64: null,
        priority: 2
      },
      stories: [],
    }
  },
  methods: {
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.storyData.base64 = await _this.readAsDataURL(file);
    },
    async setStories() {
      let _this = this;
      _this.overlay = true
      _this.stories.push(_this.storyData);

      let storySetStoryRequest = new StorySetStoryRequest(_this);
      storySetStoryRequest.setParams(_this.stories);
      await storySetStoryRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        // _this.storyData.base64 = null;
        // _this.base64ImageSrc = null;
        // _this.base64VideoSrc = null;
        // _this.$router.push('apps-stories-list')
        window.location.reload();
      }, function (error) {
        console.log(error)
      })
    },
    deletePic() {
      this.storyData.base64 = null;
      this.base64ImageSrc = null;
    },
  },
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
}
</script>

<style scoped>

</style>